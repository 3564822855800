<template>
  <div
    v-if="$can('create', 'RichMenu')"
    style="height: inherit"
  >

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <div style="margin-top: 0.3em;">
        <b-form
          ref="refForm"
          class="position-relative"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <div>
            <b-card style="margin-bottom: 0.6rem;">
              <div>
                <b-row>

                  <!-- Field: type -->
                  <b-col
                    cols="2"
                  >
                    <b-form-group
                      :label="`${$t('Rich Menu Type')}`"
                      label-for="richmenu-richmenu-type"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Rich Menu Type')"
                        rules="required"
                      >
                        <v-select
                          v-model="richMenuData.richmenu_type"
                          :options="typeOptions"
                          :reduce="val => val.value.value"
                          :clearable="false"
                          input-id="richmenu-richmenu-type"
                          :state="getValidationState(validationContext)"
                        />
                        <div>
                          <small class="text-danger">{{ validationContext.errors[0] }}</small>
                        </div>
                      </validation-provider>

                    </b-form-group>
                  </b-col>

                  <!-- Field: Name -->
                  <b-col
                    cols="4"
                  >
                    <b-form-group
                      :label="$t('Name')"
                      label-for="richmenu-name"
                    >
                      <b-form-input
                        id="richmenu-name"
                        v-model="richMenuData.name"
                        :disabled="true"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: Rich Menu ID -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      :label="$t('Rich Menu ID')"
                      label-for="richmenu-id"
                    >
                      <b-form-input
                        id="richmenu-id"
                        v-model="richMenuData.id"
                        :disabled="true"
                      />
                    </b-form-group>
                  </b-col>

                </b-row>

                <b-row>
                  <b-col cols="8">
                    <b-button
                      ref="submit"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mt-2 mr-1"
                      :disabled="busy"
                      @click="onSubmit"
                    >
                      {{ $t('Process') }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      type="reset"
                      class="mt-2"
                      @click.prevent="resetLocalForm"
                    >
                      {{ $t('Reset') }}
                    </b-button>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                    class="text-right d-none d-md-block d-lg-block"
                  >
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="success"
                      type="button"
                      class="mt-2"
                      :disabled="(tableDataLink.length > 0)? false : true"
                      @click.prevent="onRichMenuLink"
                    >
                      <feather-icon
                        icon="ArrowDownCircleIcon"
                        size="14"
                      />
                      <span class="ml-25 align-middle"> {{ `${$t('Link')} ${$t('Rich Menu')}` }}</span>
                    </b-button>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                    class="d-lg-none d-md-none d-sm-block"
                  >
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="success"
                      type="button"
                      class="mt-2"
                      :disabled="(tableDataLink > 0)? false : true"
                      @click.prevent="onRichMenuLink"
                    >
                      <feather-icon
                        icon="ArrowDownCircleIcon"
                        size="14"
                      />
                      <span class="ml-25 align-middle"> {{ `${$t('Link')} ${$t('Rich Menu')}` }}</span>
                    </b-button>
                  </b-col>
                </b-row>
              </div>

              <b-overlay
                :show="busy"
                no-wrap
                @shown="onShown"
                @hidden="onHidden"
              >
                <template #overlay>
                  <div
                    v-if="processing"
                    class="text-center p-2 bg-primary text-light rounded"
                  >
                    <feather-icon
                      icon="UploadCloudIcon"
                      size="20"
                    />
                    <div class="mb-2">
                      {{ `${$t('Processing')}...` }}
                    </div>
                    <b-progress
                      min="1"
                      max="10"
                      :value="counter"
                      variant="success"
                      height="6px"
                      class="mx-n1"
                    />
                  </div>
                  <div
                    v-else
                    ref="dialog"
                    tabindex="-1"
                    role="dialog"
                    aria-modal="false"
                    aria-labelledby="form-confirm-label"
                    class="text-center p-3"
                  >
                    <b-card-text
                      class="font-weight-bolder"
                    >
                      {{ $t('Are you sure?') }}
                    </b-card-text>
                    <div class="d-flex">
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="mr-3"
                        @click="onCancel"
                      >
                        {{ $t('Cancel') }}
                      </b-button>
                      <b-button
                        ref="dialogOk"
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        variant="outline-success"
                        @click="onOK"
                      >
                        {{ $t('Ok') }}
                      </b-button>
                    </div>
                  </div>
                </template>
              </b-overlay>
            </b-card>
          </div>

        </b-form>
      </div>
    </validation-observer>

    <!-- Table Line User -->
    <b-card
      no-body
      style="margin-top:1em;"
    >
      <b-overlay
        :show="showTable"
        :variant="variantTable"
        :opacity="opacityTable"
        :blur="blurTable"
        rounded="sm"
      >
        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>{{ `${$t('Total')} ${tableDataLink.length} ${$t('Entries')}` }}</label>
              <label class="pl-1 pr-1"><feather-icon icon="ChevronRightIcon" /></label>
              <label>{{ `${$t('Rich Menu Link')}` }}</label>
              <label style="padding-left: 0.4em; padding-right: 1em;"><feather-icon icon="ChevronsRightIcon" /></label>
              <label>{{ `${$t('Done')} ${linkCountOk}/${linkCountTotal} ${$t('Entries')}` }}</label>
              <label class="pl-1 pr-1"><feather-icon icon="ChevronRightIcon" /></label>
              <label>{{ `${$t('Error')} ${linkCountError} ${$t('Entries')}` }}</label>
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refRichMenuLinkTable"
          :items="tableDataLink"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          hover
          empty-text="No matching records found"
          class="position-relative"
        >
          <!-- Column: No -->
          <template #cell(no)="data">
            <div class="text-nowrap">
              <span class="align-text-top">{{ data.item.no }}</span>
            </div>
          </template>

          <!-- Column: User Id -->
          <template #cell(user_id)="data">
            <div class="text-nowrap">
              <span class="align-text-top">{{ data.item.user_id }}</span>
            </div>
          </template>

          <!-- Column: Picture Url -->
          <template #cell(picture_url)="data">
            <div
              class="text-nowrap"
            >
              <span class="align-text-top">
                <b-avatar
                  :src="data.item.picture_url"
                  class="mr-50"
                  size="30"
                />
              </span>
            </div>
          </template>

          <!-- Column: Display Name -->
          <template #cell(display_name)="data">
            <div class="text-nowrap">
              <span class="align-text-top">{{ data.item.display_name }}</span>
            </div>
          </template>

          <!-- Column: Richmenu Type -->
          <template #cell(richmenu_type)="data">
            <div>
              <b-badge
                pill
                :variant="(skin === 'dark')? `light-info` : 'info'"
              >
                {{ $t(textFirstUpper(data.item.link_richmenu_type)) }}
              </b-badge>
            </div>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <div>
              <b-badge
                pill
                :variant="(skin === 'dark')? `light-${data.item.statusVariant}` : data.item.statusVariant"
              >
                {{ (data.item.status !== '')? data.item.status : $t('Wait Link') }}
              </b-badge>
            </div>
          </template>
        </b-table>

      </b-overlay>
    </b-card>

  </div>
</template>

<script>
import {
  BForm,
  BCard,
  BCardText,
  BTable,
  BBadge,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BOverlay,
  BProgress,
  BFormInput,
  BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { heightFade } from '@core/directives/animations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import router from '@/router'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import { $themeConfig } from '@themeConfig'
import vSelect from 'vue-select'
import richMenuStoreModule from './richMenuStoreModule'
import tableRichMenuLink from './tableRichMenuLink'
import richMenuTypeStoreModule from '../richmenu_type/richMenuTypeStoreModule'

export default {
  components: {
    BForm,
    BCard,
    BCardText,
    BTable,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BOverlay,
    BProgress,

    ValidationProvider,
    ValidationObserver,

    FeatherIcon,
    BFormInput,
    BAvatar,

    vSelect,
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
  },
  data() {
    return {
      richMenuData: {},
      richMenuLinkData: {},
      defaultData: {},
      required,
      userData: JSON.parse(localStorage.getItem(`${$themeConfig.app.commonSession}-user-data`)),
      skin: store.state.appConfig.layout.skin,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      isAction: null,
      showTable: false,
      variantTable: this.skin.value,
      blurTable: '2px',
      opacityTable: 0.55,
      linkCountTotal: 0,
      linkCountOk: 0,
      linkCountError: 0,
      tableDataLink: [],
      typeOptions: [],
    }
  },
  computed: {
    lineOa() {
      const value = store.state.lineapi.lineOa
      if (value === '' || value === undefined) {
        return ''
      }
      return JSON.parse(value)
    },
    lineOaId() {
      const value = this.lineOa.id
      if (value === '' || value === undefined) {
        return ''
      }
      return value
    },
  },
  mounted() {
    this.richMenuTypeOptions()
    this.getRichMenu()
  },
  methods: {
    richMenuTypeOptions() {
      store
        .dispatch('richmenu-type-store/richMenuTypeOptions', { language: this.$i18n.locale, lineOaId: this.lineOaId })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.typeOptions = response.data
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
        })
    },
    getRichMenu() {
      store
        .dispatch('richmenu-store/getRichMenu', { language: this.$i18n.locale, id: router.currentRoute.params.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.richMenuData = response.data
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
        })
    },
    getRichMenuLink() {
      store
        .dispatch('richmenu-store/getRichMenuLink',
          {
            language: this.$i18n.locale,
            id: router.currentRoute.params.id,
            data: this.richMenuData,
          })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.richMenuLinkData = response.data
          this.loadDataInTable(response.data)
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
        })
    },
    loadDataInTable(results) {
      const reResults = []
      let no = 0
      results.forEach(field => {
        const fd = field
        no += 1
        fd.no = no
        fd.statusVariant = 'warning'
        fd.status = this.$i18n.t('Wait Link')
        reResults.push(fd)
      })
      this.tableDataLink = reResults

      this.linkCountTotal = 0
      this.linkCountOk = 0
      this.linkCountError = 0

      const okMsg = this.$i18n.t('Process on successfully')
      this.$toast({
        component: ToastificationContent,
        props: {
          title: okMsg,
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      },
      {
        position: 'bottom-right',
      })
      this.busy = false
      this.processing = false
      this.showTable = false
      this.$swal({
        icon: 'success',
        title: this.$i18n.t('Process'),
        text: this.$i18n.t('Your data has been {action}', { action: this.$i18n.t('Process') }),
        timer: 3000,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    resetTable() {
      this.tableDataLink = []
    },
    resetLocalForm() {
      this.processing = false
      this.busy = true
      this.isAction = 'reset'
    },
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialogOk.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
          this.isAction = 'process'
        }
      })
    },
    onRichMenuLink() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
          this.isAction = 'link'
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      this.showTable = true
      if (this.isAction === 'process') {
        this.getRichMenuLink()
      } else if (this.isAction === 'reset') {
        this.resetTable()
        this.$refs.refFormObserver.reset()
        this.busy = false
        this.processing = false
        this.showTable = false
      } else if (this.isAction === 'link') {
        this.linkRichMenu()
        this.busy = false
        this.processing = false
        this.showTable = false
      }
    },
    linkRichMenu() {
      if (this.tableDataLink.length > 0) {
        this.linkCountTotal = 0
        this.linkCountOk = 0
        this.linkCountError = 0
        this.tableDataLink.forEach(row => {
          this.linkCountTotal += 1
          const index = this.tableDataLink.findIndex(o => o.no === row.no)
          if (index >= 0) {
            this.tableDataLink[index].statusVariant = 'info'
            this.tableDataLink[index].status = this.$i18n.t('Linking')
            this.refetchData()
          }
          store.dispatch('richmenu-store/linkRichMenu', {
            id: router.currentRoute.params.id,
            type: this.richMenuData.richmenu_type,
            data: row,
            lineOaId: this.lineOaId,
          })
            .then(() => {
              let statusMessage = ''
              statusMessage = `${this.$i18n.t('Success')}`
              this.linkCountOk += 1
              if (index >= 0) {
                this.tableDataLink[index].statusVariant = 'success'
                this.tableDataLink[index].status = statusMessage
                this.refetchData()
              }
            })
            .catch(error => {
              this.linkCountError += 1
              const { response, message } = error
              let errorMessage = message
              if (response) {
                errorMessage = this.$i18n.t(response.data.error)
                if (errorMessage === '') {
                  errorMessage = this.$i18n.t(response.data.message)
                }
              }
              if (index >= 0) {
                this.tableDataLink[index].statusVariant = 'danger'
                this.tableDataLink[index].status = errorMessage
                this.refetchData()
              }
            })
        })
      } else {
        // not found
        const msg = this.$i18n.t('Not found data')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: msg,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      }
    },
  },
  setup() {
    if (!store.hasModule('richmenu-store')) {
      store.registerModule('richmenu-store', richMenuStoreModule)
    }
    if (!store.hasModule('richmenu-type-store')) store.registerModule('richmenu-type-store', richMenuTypeStoreModule)
    onUnmounted(() => {
      if (store.hasModule('richmenu-store')) store.unregisterModule('richmenu-store')
      if (store.hasModule('richmenu-type-store')) store.unregisterModule('richmenu-type-store')
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    const {
      tableColumns,
      refRichMenuLinkTable,
      refetchData,
      textFirstUpper,

    } = tableRichMenuLink()

    const { skin } = useAppConfig()

    return {
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      tableColumns,
      refRichMenuLinkTable,
      refetchData,
      textFirstUpper,

      skin,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
      .swal2-popup {
        background-color: $theme-dark-body-bg !important;
      }
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
}
</style>
